import React from 'react';
import { Box, Button, Grommet, Heading, Nav, ResponsiveContext, Anchor, Text} from 'grommet';
import { Directions, List } from 'grommet-icons'
import { TPPI } from './icons/Tppi.svg';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const theme = {
  global: {
    colors: {
      brand:"#060E17",
      "white":"#ffffff"
    },
    font: {
      family: 'Nevis',
      size: '18px',
      height: '20px',
    },
  },
};

function App() {
  return (
    <Grommet full theme={theme}>
      <ResponsiveContext.Consumer>
      {size => (
        <Box
        fill={true}
        flex={true}
        background="brand"
        >
          <Nav background="brand" direction='row' align='center' justify='start' pad="xsmall">
            <Anchor icon={<TPPI size='large' color="white"/>} margin="xsmall"/>
            <Text textAlign='end'></Text>
          </Nav>
          <Box 
            align="center"
            justify='center'
            fill={true}
            flex={true}
          >
              <Heading>TestPagePleaseIgnore</Heading>
              <Text>Click one of these links for my projects:</Text>
              <Box
                direction='row'
                align="center"
                justify='center'
              >
                <Button margin='xsmall' icon={<Directions/>} hoverIndicator label="Random Project" onClick={()=>{
          var notyf = new Notyf();
          notyf.error('Currently working on these, please check later');
        }}></Button>
                <Button margin='xsmall' icon={<List/>} hoverIndicator label="Project List" onClick={()=>{
          var notyf = new Notyf();
          notyf.error('Currently working on these, please check later');
        }}></Button>
              </Box>
          </Box>
        </Box>  
      )}   
      </ResponsiveContext.Consumer>   
    </Grommet>
  );
}

export default App;
